import React from 'react'
import HeroSection from '../components/HeroSection/HeroSection'
import ServiceSection from '../components/ServiceSection/ServiceSection'
import Discover from '../components/Discover/Discover'
import SliderSection from '../components/SliderSection/SliderSection'
import PackagesSection from '../components/PackagesSection/PackagesSection'
import BookPublish from '../components/BookPublish/BookPublish'
import DiscountSection from '../components/DiscountSection/DiscountSection'
import Footer from '../components/Footer/Footer'
import ProcessSection from '../components/ProcessSection/ProcessSection'
import Testimonials from '../components/Testimonials/Testimonials'
// import PopupModal from '../components/PopupModal/PopupModal'
function Home() {
  return (
    <div >
        <HeroSection/>
        <ServiceSection/>
        <Discover/>
        <SliderSection/>
        <PackagesSection/>
        <BookPublish/>
        <ProcessSection/>
        <Testimonials/>
        <DiscountSection/>
        <Footer/>
        {/* <PopupModal/> */}
    </div>
  )
}

export default Home