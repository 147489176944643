import React from 'react'
import './ServiceSection.css'
import Kid from '../../assets/images/kidsnew.png'
import Point1 from '../../assets/images/points_01.png'
import Point2 from '../../assets/images/points_02.png'
import Point3 from '../../assets/images/points_03.png'
import Point4 from '../../assets/images/points_04.png'

function ServiceSection() {
  return (
    <div>
        <section class="services_sections">
            <div style={{marginRight:'0px'}} class="row">
                <div style={{position:'absolute' , float:'left'}} class="col-md-5">
                    <div  class="service_image">
                        <img class="service_kid" src={Kid} alt='kid'/>
                    </div>
                </div>
        <div class="container">
                <div style={{float:'right'}} class="col-md-7">
                    <div class="inner_services">
                        <h2>
                        Incredible Children’s Books Publishing with <span class="yellow">Bellevue Publishers  </span>
                        </h2>
                        <p class="services_deptn">Bellevue Children’s Book Publishing transforms your ideas into engaging stories. Our skilled team manages all aspects, including editing, design, and professional publishing tools and techniques, ensuring your children’s book stands out on bookshelves and online platforms worldwide. Begin your publishing adventure with us today!</p>
                            <div class="ul_images">
                                <ul class="servis">
                                    <li><img src={Point1} alt='1'/>  </li>
                                    <li><img src={Point2} alt='1'/>  </li>
                                    <li><img src={Point3} alt='1'/>  </li>
                                    <li><img src={Point4} alt='1'/>  </li>
                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  )
}

export default ServiceSection