import { Toaster } from 'react-hot-toast';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router,Routes ,Route } from 'react-router-dom';
import Pricing from './pages/Pricing';
import Header from './components/Header/Header'
function App() {



  return (
    <>
    {/* <div className='max-width-container' style={{maxWidth:'1920px', justifySelf: 'center'}}> */}
       <Router>
        <Header/>
      <Routes>
        <Route path="/" element={<Home  />} />
        <Route path="/package1" element={<Pricing  />} />
      
    </Routes>
    <Toaster/>
  </Router>
  {/* </div> */}
    </>
  );
}

export default App;