import React from "react";
import Cube from "../../assets/images/cube-3.png";
import TestimonialPerson from "../../assets/images/testimonal person.png";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Testimonials.css";
function Testimonials() {
  return (
    <section class="testimoials">
      <div class="container">
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8 align-middle">
            <h2 class="testimanh">
              What do Our Clients say <span class="perpel">About Us</span>
            </h2>
            <img class="cube_3" src={Cube} alt="1" />
            <div class="lead-testimonial-cont">
              <Swiper
                cssMode={true}
                             
                pagination={{
                  type: 'custom', // Set the pagination type to 'progressbar'
                }}
                
                mousewheel={true}
                keyboard={true}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: false,
                }}
                navigation={true}
  
                modules={[
                  Navigation,
                  Pagination,
                  Mousewheel,
                  Keyboard,
                  Autoplay,
                ]}
                className="mySwiper1"
                style={{ height: "100%" }}
              >
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">Benjamin Rose </h2>
                      <span class="lead-tstmnl-comment">
                        “I love Bellevue Publishers! They make kid’s books very
                        nice and colorful. I like them a lot. The books they
                        make are so cool, and my children enjoy them immensely.”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">Christy Evans </h2>
                      <span class="lead-tstmnl-comment">
                        “I have a beautiful book from them, and it is very good
                        quality. I am happy with it and joy it brings to my
                        little book freaks. I believe Bellevue Publishers does
                        excellent work.”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">Donna Jinton </h2>
                      <span class="lead-tstmnl-comment">
                        “Quality of the book is impressive, and it’s filled with
                        lovely illustrations. Bellevue Publishers did a great
                        job with my children’s book! I highly recommend them to
                        everyone seeking high quality childrens books.”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="lead-tstmnl-slide">
                    <div class="lead-tstmnl-img">
                      <img src={TestimonialPerson} alt="1" />
                    </div>
                    <div class="lead-tstmnl-text-cont">
                      <h2 class="client_name">Logan White </h2>
                      <span class="lead-tstmnl-comment">
                        “I absolutely love Bellevue Publishers. They produce
                        childrens books of outstanding quality and interesting
                        illustrations that. I recently got a book for my
                        kindergarten school students, and it’s best addition to
                        our collection. I can’t recommend them enough!”
                      </span>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>

              {/* <div class="lead-tstmnl-slider">
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                        proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="lead-tstmnl-slide">
                                <div class="lead-tstmnl-img">
                                    <img src={TestimonialPerson} alt='1'/>
                                </div>
                                <div class="lead-tstmnl-text-cont">
                                    <h2 class="client_name">Benjiman parker</h2>
                                    <span class="lead-tstmnl-comment">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat. Duis aute irure dolor in reprehenderit in voluptate.
                                    </span>
                                    
                                </div>
                            </div>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
