import React from 'react'
import "./SliderSection.css"
import Book1 from '../../assets/images/new1.png'
import Book2 from '../../assets/images/new2.png'
import Book3 from '../../assets/images/new3.png'
import Circle from '../../assets/images/eclipps.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


function SliderSection() {
  return (
            <section class="silder_section">
        <div class="container">
            <div class="row">
           <div class="col-md-5">
            <div class="slider_contetn">
                <h1 class="slider_heading">Immerse  Yourself in  Our Gallery of <span class="yellow">Children's Book</span>  Publishing</h1>
                <div class="banner_btns">
                    <button type="button" class="btn-close avail" aria-label="Close">avail 50% off now</button>
                    <button type="button" class="btn-close pricing" aria-label="Close">view pricing</button>
                </div>
            </div>
            </div>
            <div class="col-md-7">
                <div style={{backgroundImage:`url(${Circle})`, backgroundSize:'60%', backgroundRepeat:'no-repeat',backgroundPosition:'center'}} class="img_slider">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                    }}
                    pagination={{
                        type: 'custom', // Set the pagination type to 'progressbar'
                      }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    style={{zIndex:99999}}
                    className="mySwiper7"
                >
                    <SwiperSlide ><img  style={{width:'60%'}} src={Book1} alt='1' /></SwiperSlide>
                    <SwiperSlide><img style={{width:'60%'}}  src={Book2} alt='1' /></SwiperSlide>
                    <SwiperSlide><img style={{width:'60%'}} src={Book3} alt='1' /></SwiperSlide>
                    
                </Swiper> 
                {/* <div className="arrows-container">
                    <BsArrowLeftCircleFill size={24} fill='#fff'/>
                    <BsArrowRightCircleFill size={24} fill='#fff'/>
                </div> */}
                </div>
            </div>
            </div>
        </div>
    </section>

  )
}

export default SliderSection