import React from 'react'
import './Discover.css'
import LeftBook from '../../assets/images/left-book.png'
import Sidebook from '../../assets/images/sidebook.png'
function Discover() {
  return (
          <section class="reader_discover-1">
          
           <div class="right-img">
            <img class="right" src={LeftBook} alt='left'/>
           
            </div>
                <div class="reader_content container">
                    <h3>
                    Be a Part of The Most Creative and Innocent  <span class="perpel">Children’s World </span>with many colors, silly yet catchy images, and appealing book covers.  
                    </h3>
                    <div class="banner_btns">
                        <button type="button" class="btn-close availperpal" aria-label="Close">avail 50% off now</button>
                        <button type="button" class="btn-close pricingperpal" aria-label="Close">view pricing</button>
                    </div>
                
            </div>
                <div class="left-img">
                    <img class="left" src={Sidebook} alt='side'/>
                   </div>
            
    {/* </div> */}
    </section>
  )
}

export default Discover