import React from "react";
import "./PackagesSection";
import Kid from '../../assets/images/newkid.png'
import { Divider } from "antd";
function PackagesSection() {

  return (
    <div>
      <section class="package">
        <div class="container">
          <div className="row">

          <div className="col-md-8" style={{display:'flex', overflowX:"scroll"}}>
            <div class="col-md-6">
              <div class="packages">
                <div class="package-one">
                  <h3>AMAZON PUBLISHING</h3>
                  <ul
                    class="pak_ul purpleSlider"
                    style={{height: '200px', overflow: 'auto' ,textAlign:'left'}}
                  >
                    <li>Amazon KDP Publishing</li>
                    <Divider style={{margin:'8px 0'}}/>
                    <li>Formatting Check for All File Formats</li>
                    <Divider style={{margin:'8px 0'}}/>
                    <li style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                      Multiple Print-Ready Formats
                     

                    </li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Amazon Keyword Research</li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                      Amazon Listing Optimization 
                  
                    </li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Amazon Author Central Account Creation</li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Competitive Book Pricing Advice</li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Dedicated Project Manager and Publishing Agent</li>
                    {/* <Divider style={{margin:'8px 0'}}/> */}

                  </ul>
                  <button
                    type="button"
                    class="btn-close pricingpkg"
                    aria-label="Close"
                  >
                    Order Now
                  </button>
                </div>
              </div>
            </div>
        
            <div class="col-md-6">
              <div class="packages">
                <div class="package-two">
                  <h3>MULTI-PLATFORM PUBLISHING</h3>
                  <ul
                    class="pak_ul orangeSlider"
                    style={{height: '200px', overflow: 'auto' ,textAlign:'left'}}
                  >
                    <li>KDP, Barnes & Noble, IngramSpark Publishing</li>
                    <Divider style={{margin:'8px 0'}}/>
                    <li>Formatting and File Conversions</li>
                    <Divider style={{margin:'8px 0'}}/>
                    <li style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                      Multiple Print-Ready Formats
                    
                    </li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Keyword Research</li>
                    <Divider style={{margin:'8px 0'}}/>

                   
                    <li style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                    Listing Optimization  
                    

                    </li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Amazon Author Central Account Creation</li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>Dedicated Project Manager and Publishing Agent</li>
                    <Divider style={{margin:'8px 0'}}/>

                    <li>ISBN Purchase Included</li>
                    {/* <Divider style={{margin:'8px 0'}}/> */}

                  </ul>
                  <button
                    type="button"
                    style={{color:'#f4ca55'}}
                    class="btn-close pricingpkg"
                    aria-label="Close"
                  >
                    Request A Qoute
                  </button>
                </div>
              </div>
            </div>
                    </div>
            <div class="col-md-4">
              <div  class="content_pkg">
                <h2>
                  EASY PRICING{" "}
                  <span class="perpel"><br /> 
                    FOR
                    EVERYONE
                  </span>
                </h2>
                <div class="pkg_kidsimg">
                  <img src={Kid} alt="1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PackagesSection;
