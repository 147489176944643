import React from 'react';

function Pricing() {
  return (
    <div>
      <div className="package_details_page container">
        <div className="package_details_banner row">
          <div className="col">
            <h1>Simple & transparent pricing<span>.</span></h1>
            <p>We believe Untitled should be accessible to all companies, no matter the size.</p>
          </div>
        </div>
        <div className="package_details_section row">
          <div className="col">
            <div className="row">
              <div className="col-lg-10 col-md-12 col-sm-12">
                <h2>Basic Logo Package</h2>
                <p>Suitable for potential super-startups and brand revamps for companies.</p>
              </div>
              <div className="col">
                <h2 style={{ textAlign: 'right' }}>$35</h2>
              </div>
            </div>
            <div className="package_details_inner_row row">
              <p><strong>Package includes with:</strong></p>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <p>
                  <svg
                    stroke="currentColor"
                    fill="#032e32"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    width="1em"
                    fontWeight="bold"
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: '10px' }}
                  >
                    <path
                      d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                    ></path>
                  </svg>
                  3 Custom Logo Design Concepts
                </p>
              </div>
              {/* Rest of the columns */}
            </div>
            <div className="row" style={{ justifyContent: 'center', paddingTop: '30px' }}>
              <div className="col-lg-3 col-sm-6">
                <button className="btn_blue chat" style={{ width: '100%' }}>Live Chat</button>
              </div>
              <div className="pack_btn_div col-lg-3 col-sm-6">
                <button className="btn_blue" style={{ width: '100%' }}>
                  <a href="/contact">Get Started</a>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingBottom: '70px' }}>
          <div className="row">
            <div className="col">
              <h3>Pricing FAQs</h3>
              <p>As one of the top WordPress web design agencies, we make every effort to communicate and educate. Here are some additional resources for you to learn about WordPress web design trends and our approach.</p>
            </div>
          </div>
          <div className="branding_fifth container"></div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
