export const Constant = {
    
    CompanyName: "Bellevue Publishers",
    CompanyUrl: "bellevuepublishers.com",
    CompanyUrlWithWWW: "www.americanwritersassociation.com",
    PhoneNumber: "(833) 781-8680",
    PhoneNumberLink: "tel:8337818680",
    Email: "info@americanwritersassociation.com",
    EmailLink: "mailto:info@americanwritersassociation.com",
    Address: "39300 Civic Center Dr, Fremont, CA 94536, USA",
    FacebookLink: "https://www.facebook.com/americanwritersassociation",
    InstagramLink: "https://www.instagram.com/americanwritersassociation/ ",
    LinkedinLink: "https://www.linkedin.com/company/americanwritersassociation/",
    // TwitterLink: "https://twitter.com/BellevuePublish",
    // TrustPilotLink: "https://www.trustpilot.com/review/nydhub.com",
    WhatsAppLink: "tel:(833) 781-8680",
    BASE_URL :'http://americanseohub.com/api/v1/'
    // GoogleReviewsLink: "#",
    // REACT_APP_reCAPTCHA_SITE_KEY: '6LeLj3InAAAAAKu0MpTsQtHhDM2SRjY26CLxPDCG',
    // REACT_APP_reCAPTCHA_SECRET_KEY: '6LeLj3InAAAAAJDHGPz7h1TknZq77KbI9AzY1yq9',
  };